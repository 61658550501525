<template>
  <SearchForm ref="searchFormRef" :formModel="searchController.formModel" @search="tableController.search" :searchLoading="tableController.loading">
    <template #form>
      <el-form-item :noSpan="true">
        <div>提交时间</div>
      </el-form-item>
      <el-form-item prop="dateRange">
        <el-date-picker
          v-model="searchController.formModel.dateRange"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="YYYY-MM-DD"
          :clearable="false"
          style="height: 32px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item prop="doctorName">
        <el-input placeholder="医生姓名" v-model="searchController.formModel.doctorName" clearable></el-input>
      </el-form-item>
      <el-form-item prop="doctorLoginName">
        <el-input placeholder="账号" v-model="searchController.formModel.doctorLoginName" clearable></el-input>
      </el-form-item>
      <el-form-item prop="doctorPhoneNumber">
        <el-input placeholder="手机号码" v-model="searchController.formModel.doctorPhoneNumber" clearable></el-input>
      </el-form-item>
      <!-- <el-form-item prop="apprStatus">
        <FetchSelect
          placeholder="审方状态"
          v-model="searchController.formModel.apprStatus"
          api="/web/sys/code/getSysCodeByParam"
          :postData="{ codeParam: 'PRESCRIPTION_APPR_STATUS' }"
          textKey="codeValue"
          valueKey="codeKey"
        />
      </el-form-item> -->
    </template>
  </SearchForm>
  <ProTable
    :columns="tableController.columns"
    :tableData="tableController.tableData"
    :loading="tableController.loading"
    :total="tableController.total"
    :pageState="tableController.pageState"
    @pageChange="tableController.onPageChange"
  />
  <!-- 模态 -->
  <ApprModal ref="ApprModalRef" @success="tableController.refresh" />
</template>

<script>
import { ref } from 'vue'
import dayjs from 'dayjs'
import SearchForm from '@/components/SearchForm'
import ProTable from '@/components/ProTable/index.vue'
import FetchSelect from '@/components/FetchSelect'
import CustomLink from '@/components/customStyle/CustomLink'
import ApprModal from './ApprModal.vue'
import useSearchFormCtrller from '@/hooks/useSearchFormCtrller'
import useTableController from '@/hooks/useTableController'
import { actionConfirm } from '@/utils/confirm'
import request from '@/utils/request'

// //DOCTOR_APPLY_STATUS
// public static final String DOCTOR_APPLY_STATUS_10 = "10";//待处理
// public static final String DOCTOR_APPLY_STATUS_90 = "90";//已处理

export default {
  setup() {
    const ApprModalRef = ref(null)
    // searchForm
    const searchController = useSearchFormCtrller({
      form: {
        dateRange: [dayjs().subtract(30, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
        doctorName: '',
        doctorLoginName: '',
        doctorPhoneNumber: '',
        dateType: 'RX', // 日期类型(非空 RX-下处方时间;APPR-审批时间;FINISH-完成时间)(默认:RX)
      },
    })
    // table
    const columns = [
      { label: '医生', prop: 'doctorName', width: 100 },
      { label: '医生账号', prop: 'doctorLoginName', width: 140 },
      { label: '手机号码', prop: 'doctorPhoneNumber', width: 130 },
      { label: '提交位置', prop: 'applyTypeName', width: 130 },
      { label: '提交时间', prop: 'createDateStr' },
      { label: '处理结果', prop: 'resultNote', width: 300 },
      {
        label: '操作',
        prop: 'actions',
        width: 90,
        render: (_, record) => {
          if (record.status == '10') {
            return (
              <CustomLink
                onClick={() => {
                  clickAppr(record)
                }}
              >
                待处理
              </CustomLink>
            )
          } else {
            return <div>{record.statusName}</div>
          }
        },
      },
    ]

    const tableController = useTableController({
      columns,
      ajax: ({ page, pageSize }) => {
        const { dateRange, ...values } = searchController.formModel
        const postData = {
          page,
          rows: pageSize,
          startDate: dateRange?.[0],
          endDate: dateRange?.[1],
          ...values,
        }
        return request({
          url: '/web/system/doctorapply/queryPage',
          data: postData,
        }).then((data) => ({ list: data.data ?? [], totalNum: data.rowTop ?? 0 }))
      },
    })

    function clickAppr(record) {
      ApprModalRef.value?.open(record)
    }

    return {
      searchController,
      tableController,
      ApprModalRef,
    }
  },
  components: { SearchForm, ProTable, FetchSelect, CustomLink, ApprModal },
}
</script>

<style></style>
